import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import CTA from "../components/CTA";
import Explainer from "../components/Explainer";
import SEO from "../components/SEO";
import BaseLayout from "../components/layouts/BaseLayout";
import Features from "../components/Features";

const IndexPage = () => {
  const { manDocumentsAccident, screenshotCreateReport, bruggLiftingTeam } =
    useStaticQuery(
      graphql`
        query {
          manDocumentsAccident: file(
            relativePath: { eq: "man-documents-accident.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
            }
          }
          screenshotCreateReport: file(
            relativePath: { eq: "screenshot-create-report.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
            }
          }
          bruggLiftingTeam: file(
            relativePath: { eq: "brugg-lifting-team.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
            }
          }
        }
      `
    );

  return (
    <BaseLayout isHeroVisible={true}>
      <SEO />
      <Explainer
        title="Report security relevant incidents"
        paragraphs={[
          "Using the safety app your co-workers can document security relevant events at any time using their own smartphone or tablet. Reports of almost-accidents, errors or property damage can be complemented with photos, videos, voice recordings and GPS location.",
          "After capturing all data, the report is stored in the cloud, shared with your whole team.",
        ]}
        image={screenshotCreateReport.childImageSharp.gatsbyImageData}
        imageAlt="Screenshot of the reports screen in the Safety App"
        isImageLeft={true}
      />
      <Explainer
        title="Prevent future accidents"
        paragraphs={[
          "The person who is in charge of the work environment safety is notified about every new reporting. That person is then prompted to check the report and define actions to prevent future similar accidents.",
          "For example somebody could report nails sticking out of old europallets using the app. The responsible team member is then notified and thus integrates removing those nails into the daily work routine.",
        ]}
        image={manDocumentsAccident.childImageSharp.gatsbyImageData}
        imageAlt="The person responsible for security checks for new reports to prevent future incidents"
        isImageLeft={false}
      />
      <Explainer
        title="From professionals for professionals"
        paragraphs={[
          "The Safety App was developed by BRUGG Lifting AG - one of the leading manufacturers of elevator ropes, architectural ropes and wire ropes, lashing & lifting gear. We know from our own field experience which security functions really are important for professionals. We would love to share our solution with other professionals and help to achive more security and less accidents in work environments.",
        ]}
        image={bruggLiftingTeam.childImageSharp.gatsbyImageData}
        imageAlt="The team of BRUGG Lifting AG"
        isImageLeft={true}
      />
      <Features />
      {/* <Pricing /> */}
      <CTA />
      {/* <PosterDownload /> */}
    </BaseLayout>
  );
};

export default IndexPage;
